import React, { useEffect, useState } from "react";
import apiService from "../../../services/apiService";
import CustomToast from "../../Toast";
import { useTranslation } from "react-i18next";

const FullScreenImageModal = ({ imageUrl, onClose }) => (
  <div className="fullscreen-modal">
    <button type="button" onClick={onClose} className="close-button">
      Close 
    </button>
    <img src={imageUrl} alt="Fullscreen" className="fullscreen-image" />
  </div>
);

const Evaluation = ({
  courseDetails,
  Qdata,
  userAns,
  setCompleted,
  updateLmsModule,
  setIsVisible,
  setUserAnswers,
  setModuleContent,
  setIsSubmit,
  setRestrictTheSelect
}) => {
  const { t } = useTranslation();
  const pass = {
    title: t('course_modules_test_pass_title'),
    line1: t('course_modules_test_pass_line1'),
    line2: t('course_modules_test_pass_line2'),
    line3: t('course_modules_test_pass_line3'),
    button: t('course_modules_test_pass_button'),
    color: "green",
  };
  const fail = {
    title: t('course_modules_test_fail_title'), 
    line1: t('course_modules_test_fail_line1'),
    line2: t('course_modules_test_fail_line2'),
    line3: t('course_modules_test_fail_line3'),
    button: t('course_modules_test_fail_button'),
    color: "red",
  };
  const passed = {
    title: "Congratulations!",
    line1:
      "You have successfully completed the course. You can now view and download the certificate. Take care and stay safe!",
    button: "View Certificate",
  };

  // Function to calculate total marks
  function calculateTotalMarks(questions) {
    let totalMarks = 0;
    questions?.forEach((question) => {
      totalMarks += question.marks;
    });
    return totalMarks;
  }
  // Function to calculate total marks
  function calculateGetMarks(userData) {
    let totalMarks = 0;
    userData?.forEach((question) => {
      totalMarks += question.user_marks;
    });
    return totalMarks;
  }

  function passOrFail(totalMarks, obtainedMarks, passingPercentage) {
    const percentage = (obtainedMarks / totalMarks) * 100;
    return percentage >= passingPercentage ? pass : fail;
  }

  function passOrFailStatus(totalMarks, obtainedMarks, passingPercentage) {
    const percentage = (obtainedMarks / totalMarks) * 100;
    return percentage >= passingPercentage ? "pass" : "fail";
  }

  const [Evaluation, setEvaluation] = useState(
    passOrFail(
      calculateTotalMarks(Qdata?.questions),
      calculateGetMarks(userAns),
      Qdata?.passing_percent
    )
  );
  const [response, setResponse] = useState("");
  const [isPass, setIsPass] = useState(false);
  const [showContinueButton, setShowContinueButton] = useState(false);
 // const { t } = useTranslation();

  const retry = async () => {
    setCompleted(false);
    await setModuleContent([]);
    await setUserAnswers([]);
    Qdata.questions = shuffleArray(Qdata.questions)
    setModuleContent(Qdata);
    setRestrictTheSelect(false)
    setIsSubmit(true);
  };

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }
  
  const submit = () => {
    setRestrictTheSelect(true)
    Evaluation?.button === "Try Again" || Evaluation?.button == "फिर से प्रयास करें।" 
    ? retry()
      : updateLmsModule(userAns, "test", true);
  };

  const submitUserTestResult = async () => {
    try {
      const payload = {
        course_id: courseDetails?.course_id,
        user_id: courseDetails?.user_id,
        org_id: courseDetails?.org_id,
        test_type: Qdata?.course_test_type,
        total_marks: calculateTotalMarks(Qdata?.questions),
        user_attempts: 1,
        user_marks: calculateGetMarks(userAns),
        totalAttempt: Qdata?.max_attempts,
        user_course_status: passOrFailStatus(
          calculateTotalMarks(Qdata?.questions),
          calculateGetMarks(userAns),
          Qdata?.passing_percent
        ),
        completion_percent: ""
      };
      const token = apiService.getAuthToken();
      const result = await apiService.postLms(
        "saveUserTestResult",
        payload,
        token
      );
      if (result && result.status == "success") {
        console.log(result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(()=>{
  //   submitUserTestResult()
  // },[])

  const getCertificate = async () => {
    let user_details = sessionStorage.getItem("user_details");
    let data = {
      course_id: courseDetails?.course_id,
      send_email: true,
      user_id: JSON.parse(user_details)?.uid,
    };
    const token = apiService.getAuthToken();
    await apiService.postV1(`generate-certificate`, data, token);
  };

  return (
    <div className="custom-overlay video-questions quiz">
      <div className="d-flex flex-column justify-content-center align-items-center text-center">
        <div className="card w-80 p-3">
          <a
            className="w-100 text-end test-decoration-none"
            onClick={() => {
              setIsVisible(true);
              setCompleted(false);
            }}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 29 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.548828"
                width="27.4625"
                height="27.4517"
                rx="13.7259"
                fill="#146B90"
              />
              <path
                d="M19.619 17.6867C20.0081 18.0758 20.0081 18.6811 19.619 19.0702C19.4245 19.2647 19.1867 19.3512 18.9273 19.3512C18.6679 19.3512 18.4301 19.2647 18.2355 19.0702L14.2796 15.1143L10.3237 19.0702C10.1292 19.2647 9.89141 19.3512 9.63201 19.3512C9.3726 19.3512 9.13482 19.2647 8.94027 19.0702C8.55116 18.6811 8.55116 18.0758 8.94027 17.6867L12.8962 13.7308L8.94027 9.77492C8.55116 9.38582 8.55116 8.78054 8.94027 8.39144C9.32937 8.00233 9.93464 8.00233 10.3237 8.39144L14.2796 12.3473L18.2355 8.39144C18.6246 8.00233 19.2299 8.00233 19.619 8.39144C20.0081 8.78054 20.0081 9.38582 19.619 9.77492L15.6631 13.7308L19.619 17.6867Z"
                fill="white"
              />
            </svg>
          </a>
          <form className="m-3 mt-0">
            <h3 className="fw-bold font-26 mb-4">{Evaluation.title}</h3>
            <ul className="d-flex flex-column justify-content-start mcq-options">
              <h4 style={{ color: `${Evaluation.color}` }}>
                <b>
                  {calculateGetMarks(userAns)}/
                  {calculateTotalMarks(Qdata?.questions)}
                </b>
              </h4>
              <p className="mb-4">{t('course_modules_test_scores')}</p>
              <p className="mb-2">{Evaluation.line1}</p>
              <p className="mb-4">{Evaluation.line2}</p>
              <p className="mb-3">{Evaluation.line3}</p>
            </ul>
            {Evaluation?.button == "Try Again" || Evaluation?.button == "फिर से प्रयास करें।" ? (
              <button
                className="btn btn-md btn-primary"
                type="button"
                onClick={() => {
                  setIsVisible(true);
                  setCompleted(false);
                }}
              >
                {t('course_modules_show_test_result')}
              </button>
            ) : null}
            &nbsp;&nbsp;
            <button
              className="btn btn-md btn-primary"
              type="button"
              onClick={submit}
            >
              {Evaluation?.button}
            </button>
          </form>
          {/* //  : <form className='m-3' >
      //     <h3 className='fw-bold font-26 mb-4'>{passed.title}</h3>
      //     <ul className='d-flex flex-column justify-content-start mcq-options'>
      //       <p>{passed.line1}</p>
      //     </ul>
      //     <button className='btn btn-md btn-primary' type="button"  onClick={getCertificate}>
      //       {passed?.button}
      //     </button>
      //   </form> */}
        </div>
      </div>
    </div>
  );
};

const Quiz = ({ moduleContents, courseDetails, updateLmsModule, isPass }) => {
  const [answers, setAnswers] = useState({});
  const [fullscreenImage, setFullscreenImage] = useState(null);
  const [userAnswers, setUserAnswers] = useState([]);
  const [completed, setCompleted] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [isSubmit, setIsSubmit] = useState(true);
  const [moduleContent, setModuleContent] = useState(moduleContents);
  const [disabledSubmit, setDisabledSubmit] = useState(false)
  const [restrictTheSelect, setRestrictTheSelect] = useState(false);
  const { t } = useTranslation();
  const [showAndHide, setShowAndHide] = useState(t('course_modules_show_result'));
  const [isUserPass, setIsUserPass] = useState(false)

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    // moduleContent.questions = shuffleArray(moduleContent.questions)
    setModuleContent(moduleContents);
  }, [moduleContents]);

  const retry = async () => {
    // setCompleted(false)
    setIsSubmit(true);
    window.scrollTo({ top: 0, behavior: "smooth" });
    await setModuleContent([]);
    await setUserAnswers([]);
    moduleContent.questions = shuffleArray(moduleContent.questions)
    setModuleContent(moduleContents);
    setIsVisible(false);
  };

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const hideShowGeneralQuestion = () => {
    if (isShow) {
      setIsShow(false);
      setShowAndHide(t('course_modules_show_result'));
    } else {
      setIsShow(true);
      setShowAndHide(t('course_modules_hide_result'));
    }
  };

  const handleMarkAsCompleted = (userAnswers, value) => {
    if (userAnswers?.length == moduleContent?.questions?.length) {
      submitUserTestResult();
      setIsSubmit(false);
      window.scrollTo({ top: 0, behavior: "smooth" });
      setCompleted(true);
    } else {
      CustomToast.error(t('toast_messages_questions_submit_test_error'));
    }
  };

  function getTotalLength(arr) {
    return arr.reduce((total, obj) => {
      if (obj.sub_questions.length > 1) {
        // Add length of nested array 'l' within the object
        total += obj.sub_questions.length;
      } else {
        total += 1;
      }

      return total;
    }, 0);
  }

  // Function to calculate total marks
  function calculateTotalMarks(questions) {
    let totalMarks = 0;
    questions?.forEach((question) => {
      totalMarks += question.marks;
    });
    return totalMarks;
  }
  // Function to calculate total marks
  function calculateGetMarks(userData) {
    let totalMarks = 0;
    userData?.forEach((question) => {
      totalMarks += question.user_marks;
    });
    return totalMarks;
  }

  function passOrFailStatus(totalMarks, obtainedMarks, passingPercentage) {
    const percentage = (obtainedMarks / totalMarks) * 100;
    return percentage >= passingPercentage ? "pass" : "fail";
  }

  const submitUserTestResult = async () => {
    try {
      //check user or fail
      const userTestResult = passOrFailStatus(
        calculateTotalMarks(moduleContent?.questions),
        calculateGetMarks(userAnswers),
        moduleContent?.passing_percent
      )
      const payload = {
        course_id: courseDetails?.course_id,
        user_id: courseDetails?.user_id,
        org_id: courseDetails?.org_id,
        test_type: moduleContent?.course_test_type,
        total_marks: calculateTotalMarks(moduleContent?.questions),
        user_attempts: 1,
        user_marks: calculateGetMarks(userAnswers),
        totalAttempt: moduleContent?.max_attempts,
        user_course_status: userTestResult,
        completion_percent: courseDetails?.completion_percent?.toString(),
        test_lang: localStorage.getItem('userLang') || 'English'
      };
      if(userTestResult == "pass") {
        setIsUserPass(true)
      }
      const token = apiService.getAuthToken();
      const result = await apiService.postLms(
        "saveUserTestResult",
        payload,
        token
      );
      if (result && result.status) {
        // if(userTestResult == "pass" && type == "autoSubmit" && userAnswers?.length > 0) {
        //   handleAlreadyMarkAsCompleted(userAnswers, value, type)
        // }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAlreadyMarkAsCompleted = (userAnswers, value, type) => {
    if (userAnswers && userAnswers.length > 0) {
      submitUserTestResult();
    }
    if (type == "G") {
      // setRestrictTheSelect(true)
      // setIsVisible(true);
      // setIsSubmit(false);
      if(userAnswers?.length==moduleContent?.questions?.length){
        console.log(userAnswers);
        setDisabledSubmit(true)
        setIsVisible(true)
        setIsSubmit(false)
        return
        }else{
          CustomToast.error(t('toast_messages_questions_submit_test_error'));
          return
        }
    } 
    if (value) {
      console.log(getTotalLength(moduleContent?.questions), userAnswers);
      if (moduleContent?.questions.length !== userAnswers?.length) {
        CustomToast.error(t('toast_messages_questions_submit_test_error'));
      } else {
        updateLmsModule(userAnswers, "test", value, type).then((res) => {
          // if (res && Object.keys(res).length && res?.moduleContents && type =="G") {
          //   window.location.reload(true);
          // } else {
          //   if(moduleContent?.course_test_type == "base_line" && !moduleContent?.is_completed) {
          //     setUserAnswers([]);
          //   }
          // }
        });
      }
    } else {
      updateLmsModule(userAnswers, "test", value, type).then((res) => {
        // if (res && Object.keys(res).length && res?.moduleContents) {
        //   setModuleContent(res?.moduleContents);
        //   setUserAnswers([]);
        // } else {
        //   setUserAnswers([]);
        // }
      });
    }
    // .then(data=>{
    // window.scrollTo({ top: 0, behavior: 'smooth' });
    // setCompleted(true);
    // console.log(data,"ldskmkmdimdoifc");
    //  }).catch((error)=>{
    // console.log(error,"ndsjcnsdnvcdsi");
    //  })
    //  console.log("sdfsdds",data);
  };

  const handleImageFullScreen = (imageUrl) => {
    setFullscreenImage(imageUrl);
  };

  const handleCloseFullscreen = () => {
    setFullscreenImage(null);
  };

  const handleSliderChange = (value, questionId, index) => {
    // setAnswers((prevAnswers) => ({ ...prevAnswers, [questionId]: value }));
    // setUserAnswers((prevAnswers) => ({ ...prevAnswers, [questionId]: value }))
    const isUserCorrect = checkUserCorrectnessOnSlider(questionId, value);
    // Check if the question is already present in userAnswers
    const existingAnswerIndex = userAnswers?.findIndex(
      (ans) => ans?.qid === questionId
    );

    if (existingAnswerIndex !== -1) {
      // If the question exists, update the existing entry
      setUserAnswers((prevAnswers) => [
        ...prevAnswers.slice(0, existingAnswerIndex),
        {
          is_user_correct: isUserCorrect,
          user_answer: [value],
          qid: questionId,
          user_marks: isUserCorrect
            ? moduleContent?.questions[index - 1]?.marks
            : 0,
          course_test_type: moduleContent?.course_test_type  
        },
        ...prevAnswers.slice(existingAnswerIndex + 1),
      ]);
    } else {
      // If the question does not exist, add a new entry
      setUserAnswers((prevAnswers) => [
        ...prevAnswers,
        {
          is_user_correct: isUserCorrect,
          user_answer: [value],
          qid: questionId,
          user_marks: isUserCorrect
            ? moduleContent?.questions[index - 1]?.marks
            : 0,
          course_test_type: moduleContent?.course_test_type   
        },
      ]);
    }
  };

  // Function to check if user's answer is correct
  const checkUserCorrectnessOnSlider = (questionId, selectedOptions) => {
    const question = moduleContent?.questions.find((q) => q.qid === questionId);
    if (!question) {
      console.error(`Question with id ${questionId} not found.`);
      return false;
    }

    return selectedOptions == question?.rating?.ans;
  };

  const renderSliderFeedback = (question, questionNumber, length) => {
    const updateSliderStyle = (sliderId, value, max) => {
      const progress =
        ((value - question.rating.min) / (max - question.rating.min)) * 100;
      const rangeSlider = document.getElementById(sliderId);
      if (rangeSlider) {
        rangeSlider.style.background = `linear-gradient(to right, #146B90 0%, #146B90 ${progress}%, #ddd ${progress}%, #ddd 100%)`;
      }
    };

    return (
      <div>
        {length > 1 ? (
          <h3 className="fw-bold font-20 text-dark-grey">{t('course_modules_question')} {`${questionNumber}`}</h3>
        ) : null}
        <div dangerouslySetInnerHTML={{ __html: question?.content }} />
        <div className="sliderticks">
          {question &&
            question.rating &&
            Array.from(
              {
                length:
                  (question.rating.max - question.rating.min) /
                    question.rating.interval +
                  1,
              },
              (_, index) => (
                <span key={index}>
                  {question.rating.min + index * question.rating.interval}
                </span>
              )
            )}
        </div>
        <input
          type="range"
          id={`range-slider-${questionNumber}`}
          min={question?.rating?.min}
          max={question?.rating?.max}
          step={question?.rating?.interval}
          disabled={question?.user_answer?.length > 0}
          onChange={(e) => {
            updateSliderStyle(
              `range-slider-${questionNumber}`,
              parseInt(e.target.value, 10),
              e.target.max
            );
            handleSliderChange(
              parseInt(e.target.value, 10),
              question.qid,
              questionNumber
            );
          }}
          onInput={(e) => {
            updateSliderStyle(
              `range-slider-${questionNumber}`,
              parseInt(e.target.value, 10),
              e.target.max
            );
          }}
        />
        <p className="font-16 text-dark-grey" id="satisfaction-level">
          Your satisfaction level:{" "}
          {
            userAnswers?.find((item) => item.qid === question.qid)
              ?.user_answer[0]
          }
        </p>
        {renderFeedback(question)}
      </div>
    );
  };

  const renderFeedback = (question) => {
    const satisfactionLevel = answers[question.id] || question.min;
    const matchingFeedback = question?.feedback?.find(
      (feedback) =>
        satisfactionLevel >= feedback?.min && satisfactionLevel <= feedback?.max
    );
    return (
      <p className="font-16 text-dark-grey">
        {matchingFeedback ? matchingFeedback.text : ""}
      </p>
    );
  };

  const handleSubmit = () => {
    const results = moduleContent.questions.map((question) => {
      const selectedAnswer = answers[question.id];
      const isCorrect = Array.isArray(selectedAnswer)
        ? selectedAnswer.sort().toString() ===
          question.correctAnswer.sort().toString()
        : selectedAnswer === question.correctAnswer;
      return isCorrect;
    });

    // onQuizSubmit(results);
  };
  // <option value='rating'>Rating</option>

  // Function to handle user selection for a question
  const handleOptionSelect = (questionId, selectedOptions, index) => {
    setIsVisible(false);
    const isUserCorrect = checkUserCorrectness(questionId, selectedOptions);
    // Check if the question is already present in userAnswers
    const existingAnswerIndex = userAnswers?.findIndex(
      (ans) => ans?.qid === questionId
    );
    if (existingAnswerIndex !== -1) {
      // If the question exists, update the existing entry
      setUserAnswers((prevAnswers) => [
        ...prevAnswers.slice(0, existingAnswerIndex),
        {
          is_user_correct: isUserCorrect,
          user_answer: Array.isArray(selectedOptions)
            ? selectedOptions
            : [selectedOptions],
          qid: questionId,
          user_marks: isUserCorrect
            ? moduleContent?.questions[index - 1]?.marks
            : 0,
          course_test_type: moduleContent?.course_test_type  
        },
        ...prevAnswers.slice(existingAnswerIndex + 1),
      ]);
    } else {
      // If the question does not exist, add a new entry
      setUserAnswers((prevAnswers) => [
        ...prevAnswers,
        {
          is_user_correct: isUserCorrect,
          user_answer: Array.isArray(selectedOptions)
            ? selectedOptions
            : [selectedOptions],
          qid: questionId,
          user_marks: isUserCorrect
            ? moduleContent?.questions[index - 1]?.marks
            : 0,
          course_test_type: moduleContent?.course_test_type
        },
      ]);
    }
  };

  const renderQuestionContent = (
    question,
    questionNumber,
    passing_percent,
    length,
    is_completed,
    is_started,
    course_test_type
  ) => {
    function isCorrectById(id, user_answer) {
      if(user_answer?.length > 1) {
        return isMultipleCorrectById(user_answer)
      } else {
        const filterOptions = question.options?.filter((ele => ele?.is_correct))
        if(filterOptions?.length > 1 && user_answer?.length == 1) {
          return false
        } else {
          const match = question.options?.find(
            (item) => item._id === id && item.is_correct
          );
          return !!match;
        }
      }
    }

    function isMultipleCorrectById(userAnswers) {
      // Filter correct options from the question's options
      const correctOptions = question?.options?.filter(option => option.is_correct);
      // If there are no correct options or only one correct option, return false
      if (!correctOptions || correctOptions?.length <= 1) {
        return false;
      }
    
      // Check if the user answers match the correct options
      const allAnswersCorrect = userAnswers?.every(userAnswer => 
        correctOptions?.some(correctOption => correctOption._id === userAnswer)
      );
    
      // Return true if the number of user answers matches the number of correct options and all answers are correct
      return userAnswers?.length === correctOptions.length && allAnswersCorrect;
    }

    switch (question.question_type) {
      case "single_choice":
      case "multi_choice":
      case "bool":
        return (
          <div>
            {console.log("length====>", length)}
            {length > 1 ? (
              <h3 className="fw-bold font-20 text-dark-grey">{t('course_modules_question')} {`${questionNumber}`}</h3>
            ) : null}
            <div dangerouslySetInnerHTML={{ __html: question?.content }} />
            <ul className="mcq-options">
              {question?.options?.map((option, index) => (
                <>
                  {console.log(question?.user_answer?.length > 0 || !isSubmit)}
                  <li key={index}>
                    <label className={
                          question.question_type === "single_choice" ||
                          question.type === "bool"
                            ? "radio-button-container"
                            : "checkbox-button-container"
                        }>
                      <input
                        type={
                          question.question_type === "single_choice" ||
                          question.type === "bool"
                            ? "radio"
                            : "checkbox"
                        }
                        name={`question_${question._id}`}
                        value={option._id}
                        checked={question?.user_answer?.find(
                          (ans) => ans === option._id
                        )}
                        // disabled={
                        //   question?.user_answer?.length > 0 || !isSubmit
                        // }
                        disabled={
                          question?.user_answer?.length > 0 || !isSubmit
                        }
                        onChange={(e) => {
                          const selectedOptions =
                            question.question_type === "single_choice"
                              ? e.target.value
                              : e.target.checked
                              ? [
                                  ...(userAnswers.find(
                                    (ans) => ans.qid === question.qid
                                  )?.user_answer || []),
                                  option._id,
                                ]
                              : (
                                  userAnswers.find(
                                    (ans) => ans.qid === question.qid
                                  )?.user_answer || []
                                ).filter((opt) => opt !== option._id);
                          handleOptionSelect(
                            question.qid,
                            selectedOptions,
                            questionNumber
                          );
                        }}
                      />
                        <span class="checkmark"></span>
                      <div
                        dangerouslySetInnerHTML={{ __html: option?.content }}
                      />
                    </label>
                  </li>
                </>
              ))}
              {(question?.user_answer?.length > 1 ? [question?.user_answer[0]] :question?.user_answer )?.map((itemQ, i) =>
                isShow &&
                (question?.ans_view == "show_ans_with_exp" ||
                  question?.ans_view == "show_ans") ? (
                  <div className="w-100 text-start my-4">
                    <div
                      className={
                        isCorrectById(itemQ, question?.user_answer)
                          ? "correct p-3"
                          : "incorrect p-3 flex-column align-items-start"
                      }
                    >
                      <span>
                        {isCorrectById(itemQ, question?.user_answer) ? (
                          <p className="mb-3 fw-bold">{t('course_modules_correct')} </p>
                        ) : (
                          <p className="mb-3 fw-bold">{t('course_modules_incorrect')} </p>
                        )}
                        {/* {!isCorrect && */}
                        <span className="mb-0 text-black">
                          {question?.ans_view == "show_ans_with_exp" ? (
                            <div>
                              {isCorrectById(itemQ, question?.user_answer) ? (
                                <div className="my-2">
                                  <p className="mt-2">
                                    {question?.crt_ans_exp}
                                  </p>
                                </div>
                              ) : (
                                <p>
                                  {" "}
                                  {question?.wrng_ans_exp ? (
                                    <div className="my-2"></div>
                                  ) : null}
                                  {question?.wrng_ans_exp}
                                </p>
                              )}
                            </div>
                          ) : null}

                          {!isCorrectById(itemQ, question?.user_answer) ? (
                            <span className="fw-bold">
                              {" "}
                              <div className="mb-3">
                              {t('course_modules_correct_answer')}:
                              </div>{" "}
                              {question?.options?.map((option, index) =>
                                option?.is_correct ? (
                                  <ul
                                    className={
                                      question.question_type ===
                                        "single_choice" ||
                                      question.type === "bool"
                                        ? "nopoints"
                                        : "points"
                                    }
                                  >
                                    <li key={option?._id} 
                                      style={{
                                        fontWeight: "normal",
                                        marginBottom: 0,
                                      }}
                                      dangerouslySetInnerHTML={{
                                        __html: option?.content,
                                      }}
                                    />
                                  </ul>
                                ) : null
                              )}
                            </span>
                          ) : null}
                        </span>
                      </span>
                    </div>
                  </div>
                ) : null
              )}
              {userAnswers?.map((itemQ) =>
                isVisible &&
                question?.qid == itemQ?.qid &&
                (question?.ans_view == "show_ans_with_exp" ||
                  question?.ans_view == "show_ans") ? (
                  <div className="w-100 text-start my-4">
                    <div
                      className={
                        itemQ?.is_user_correct
                          ? "correct p-3"
                          : "incorrect p-3 flex-column align-items-start"
                      }
                    >
                      <span>
                        {itemQ?.is_user_correct ? (
                          <p className="mb-3 fw-bold">{t('course_modules_correct')}</p>
                        ) : (
                          <p className="mb-3 fw-bold">{t('course_modules_incorrect')}</p>
                        )}
                        {/* {!isCorrect && */}
                        <span className="mb-0 text-black">
                          {question?.ans_view == "show_ans_with_exp" ? (
                            <div>
                              {itemQ?.is_user_correct ? (
                                <div className="my-2">
                                  <p className="mt-2">
                                    {question?.crt_ans_exp}
                                  </p>
                                </div>
                              ) : (
                                <p>
                                  {" "}
                                  {question?.wrng_ans_exp ? (
                                    <div className="my-2"></div>
                                  ) : null}
                                  {question?.wrng_ans_exp}
                                </p>
                              )}
                            </div>
                          ) : null}

                          {!itemQ?.is_user_correct ? (
                            <span className="fw-bold">
                              <div className="mb-3">{t('course_modules_correct_answer')}:</div>{" "}
                              {question?.options?.map((option, index) =>
                                option?.is_correct ? (
                                  <ul
                                    className={
                                      question.question_type ===
                                        "single_choice" ||
                                      question.type === "bool"
                                        ? "nopoints"
                                        : "points"
                                    }
                                  >
                                    <li key={option?._id} 
                                      style={{
                                        fontWeight: "normal",
                                        marginBottom: 0,
                                      }}
                                      dangerouslySetInnerHTML={{
                                        __html: option?.content,
                                      }}
                                    />
                                  </ul>
                                ) : null
                              )}
                            </span>
                          ) : null}
                        </span>
                      </span>
                    </div>
                  </div>
                ) : null
              )}
            </ul>
          </div>
        );
      case "para":
        return (
          <div>
            {length > 1 ? (
              <h3 className="fw-bold font-20 text-dark-grey">{t('course_modules_question')} {`${questionNumber}`}</h3>
            ) : null}
            <div dangerouslySetInnerHTML={{ __html: question?.content }} />
            <ul className="mcq-questions ps-0">
              {question?.sub_questions?.map((sub_questions, index) => (
                <div className="mt-4">
                  {/* <h3 className='fw-bold font-20 text-dark-grey'>{`Question ${index + 1}`}</h3> */}
                  <div className="d-flex justify-content-start pt-2">
                    <span className="fw-semibold me-2">
                      {`Q. ${questionNumber}.${index + 1}`}{" "}
                    </span>
                    <div
                      className="sub-question"
                      dangerouslySetInnerHTML={{
                        __html: sub_questions?.content,
                      }}
                    />
                  </div>
                  <ul className="mcq-options">
                    {sub_questions?.options?.map((option, index) => (
                      <li key={index}>
                        <label className={
                          question.question_type === "single_choice" ||
                          question.type === "bool"
                            ? "radio-button-container"
                            : "checkbox-button-container"
                        }>
                          <input
                            type={
                              sub_questions.question_type === "single_choice" ||
                              sub_questions.type === "bool"
                                ? "radio"
                                : "checkbox"
                            }
                            name={`question_${sub_questions._id}`}
                            value={option._id}
                            disabled={sub_questions?.user_answer?.length > 0}
                            checked={sub_questions?.user_answer?.find(
                              (ans) => ans === option._id
                            )}
                            onChange={(e) => {
                              const selectedOptions =
                                sub_questions?.question_type === "single_choice"
                                  ? e.target.value
                                  : e.target.checked
                                  ? [
                                      ...(userAnswers.find(
                                        (ans) => ans?.qid === sub_questions.qid
                                      )?.user_answer || []),
                                      option._id,
                                    ]
                                  : (
                                      userAnswers.find(
                                        (ans) => ans.qid === sub_questions.qid
                                      )?.user_answer || []
                                    ).filter((opt) => opt !== option._id);
                              handleOptionSelect(
                                sub_questions.qid,
                                selectedOptions,
                                questionNumber
                              );
                            }}
                          />
                          <span class="checkmark"></span>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: option?.content,
                            }}
                          />
                        </label>
                      </li>
                    ))}

                    {/* {sub_questions?.user_answer?.map((itemQ) => ( */}

                    {isShow &&
                    (sub_questions?.ans_view == "show_ans_with_exp" ||
                      sub_questions?.ans_view == "show_ans") ? (
                      <div className="w-100 text-start my-4">
                        <div className={"correct p-3"}>
                          <span>
                            {/* { <p className='mb-0'>Correct </p> 
                     : <p className='mb-0'>Incorrect </p>} */}
                            {/* {!isCorrect && */}
                            <span className="mb-0 text-black">
                              {t('course_modules_correct_answer')}:
                              <span className="fw-bold">
                                {" "}
                                {sub_questions?.options?.map((option, index) =>
                                  option?.is_correct ? (
                                    <span key={option?._id}>
                                      {t('course_modules_option')} {index + 1}
                                    </span>
                                  ) : null
                                )}
                              </span>
                              {sub_questions?.ans_view ==
                              "show_ans_with_exp" ? (
                                <div>
                                  <p className="mt-3 mb-2"></p>
                                  {sub_questions?.crt_ans_exp}{" "}
                                  {sub_questions?.wrng_ans_exp ? (
                                    <p className="mt-3 mb-2"></p>
                                  ) : null}
                                  {sub_questions?.wrng_ans_exp}
                                </div>
                              ) : null}
                            </span>
                          </span>
                        </div>
                      </div>
                    ) : null}
                    {/* // : null))} */}
                  </ul>
                </div>
              ))}
            </ul>
          </div>
        );
      // case 'image-options':
      //   return (
      //     <div>
      //       <h3 className='fw-bold font-26 text-dark-grey'>{`Question ${questionNumber}`}</h3>
      //       <p className='font-16 text-dark-grey'>{`${question.text}`}</p>
      //       {renderImageOptions(question)}
      //     </div>
      //   );
      case "rating":
        return renderSliderFeedback(question, questionNumber, length);
      default:
        return null;
    }
  };

  // Function to check if user's answer is correct
  const checkUserCorrectness = (questionId, selectedOptions) => {
    const question = moduleContent?.questions.find((q) => q.qid === questionId);
    if (!question) {
      console.error(`Question with id ${questionId} not found.`);
      return false;
    }
    const correctOptions = question.options.filter((opt) => opt.is_correct);
    selectedOptions = Array.isArray(selectedOptions)
      ? selectedOptions
      : [selectedOptions];
    // Check if the selected options match the correct options
    return (
      selectedOptions.length === correctOptions.length &&
      selectedOptions.every((opt) =>
        correctOptions.some((co) => co._id === opt)
      )
    );
  };

  const renderImageOptions = (question) => (
    <ul className="mcq-options images ">
      {question.options.map((option, index) => (
        <li key={index}>
          <label>
            <span>
              <input
                type="checkbox"
                name={`question_${question.id}`}
                // value={index}
                onChange={(e) => {
                  const selectedOptions = e.target.checked
                    ? [
                        ...userAnswers.find((ans) => ans.qid === question.qid)
                          ?.user_answer,
                        option._id,
                      ]
                    : userAnswers
                        .find((ans) => ans.qid === question.qid)
                        ?.user_answer.filter((opt) => opt !== option._id);
                  handleOptionSelect(question.qid, selectedOptions);
                }}
                // onChange={() => handleOptionSelect(question.id, index)}
              />
              {option.label}
            </span>
            <span className="position-relative">
              <img
                src={option.image}
                alt={`Option ${index + 1}`}
                onClick={() => handleImageFullScreen(option.image)}
              />
            </span>
          </label>
        </li>
      ))}
    </ul>
  );

  return (
    <div className="quiz">
      <div dangerouslySetInnerHTML={{ __html: moduleContent?.content }} />
      <hr />
      <form>
        {console.log("questions==>", moduleContent?.questions)}
        {moduleContent?.questions?.map((question, index) => (
          <div key={question._id} className="mb-lg-5 mb-4 questions">
            {renderQuestionContent(
              question,
              index + 1,
              moduleContent?.passing_percent,
              moduleContent?.questions?.length || 0,
              moduleContent?.is_completed,
              moduleContent?.is_started,
              moduleContent?.course_test_type
            )}
          </div>
        ))}
        {completed && (
          <Evaluation
            courseDetails={courseDetails}
            Qdata={moduleContent}
            userAns={userAnswers}
            setCompleted={setCompleted}
            updateLmsModule={updateLmsModule}
            setIsVisible={setIsVisible}
            setUserAnswers={setUserAnswers}
            setModuleContent={setModuleContent}
            setIsSubmit={setIsSubmit}
            setRestrictTheSelect={setRestrictTheSelect}
            // onClose={closeOverlay}
            // onSubmit={handleQuizSubmit}
            // showResponse={showResponse}
            // currentQuestion={currentQuestion}
            // isCorrect={isCorrect}
            // continueToVideo={continueToVideo}
          />
        )}
        {console.log("isUserPass", isUserPass)}
        <div className="text-end">
          {!moduleContent?.is_completed &&
            moduleContent?.course_test_type == "end_line" && !isUserPass && (
              <button
                type="button"
                className="btn btn-md btn-primary"
                onClick={() =>
                  isVisible ? retry() : handleMarkAsCompleted(userAnswers, true)
                }
              >
                {isVisible ? t('course_modules_try_again') : t('course_modules_submit')}
              </button>
              
            ) }
          {!moduleContent?.is_completed &&
            moduleContent?.course_test_type == "end_line" && isUserPass && (
              <button
                type="button"
                className="btn btn-md btn-primary"
                onClick={() => handleAlreadyMarkAsCompleted(userAnswers, true)
                }
              >
                {t('course_modules_next')}
              </button>
              
          ) }  
          {!moduleContent?.is_completed &&
            moduleContent?.course_test_type == "base_line" && (
              <button
                type="button"
                className="btn btn-md btn-primary"
                onClick={() => handleAlreadyMarkAsCompleted(userAnswers, true)}
              >
                 {t('course_modules_submit')}
              </button>
            )}
          {!moduleContent?.is_completed &&
            moduleContent?.course_test_type == "general_line" && (
              <button
                type="button"
                className="btn btn-md btn-primary submit-btn"
                disabled={disabledSubmit}
                onClick={() =>
                  handleAlreadyMarkAsCompleted(userAnswers, true, "G")
                }
              >
                {t('course_modules_submit')}
              </button>
            )}
          &nbsp;&nbsp;
          {!moduleContent?.is_completed &&
            moduleContent?.course_test_type == "general_line" && (
              <button
                type="button"
                disabled={isSubmit}
                className="btn btn-md btn-primary"
                onClick={() => handleAlreadyMarkAsCompleted(userAnswers, true)}
              >
                 {t('course_modules_next')}
              </button>
            )}
          {moduleContent?.is_completed &&
            (moduleContent?.course_test_type == "general_line" ||
              moduleContent?.course_test_type == "end_line") && (
              <button
                type="button"
                className="btn btn-md btn-primary"
                onClick={() => hideShowGeneralQuestion()}
              >
                {showAndHide}
              </button>
            )}
            
          &nbsp;&nbsp;
          {moduleContent?.is_completed && (
            <button
              type="button"
              className="btn btn-md btn-primary"
              onClick={() => handleAlreadyMarkAsCompleted(userAnswers, false)}
            >
              {t('course_modules_next')}
            </button>
          )}
        </div>
      </form>
      {fullscreenImage && (
        <FullScreenImageModal
          imageUrl={fullscreenImage}
          onClose={handleCloseFullscreen}
        />
      )}
    </div>
  );
};

export default Quiz;

// if => answers is correct => display correct answers explanation
// if => answers is wrong = display wrong answers explanation + correct answer
